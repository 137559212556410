import React from 'react';
import Layout from '../components/layout'
import {graphql} from 'gatsby';
import {SocialLinksBar} from 'components/social'
import {ReactComponent as EnvelopeIcon} from 'images/icons/envelope.svg'
import {ReactComponent as PinIcon} from 'images/icons/location-pin.svg'
import {ReactComponent as PhoneIcon} from 'images/icons/phone.svg'
import {TitleBanner} from 'components/banners/titleBanner'
import Map from 'components/map'
import Form from 'components/gravityforms'
import Styles from './contactus.module.css'
import {BgPattern} from 'components/backgrounds'
import BgStyle from 'components/backgrounds/patterns.module.css'
import TwitterTimeline from 'components/twitterTimeLine'

const ContactUsTemplate = ({data, ...props}) => {
  return (
    <Layout
      articleId={`${data.page.type}-${data.page.wordpress_id}`}
      context = {props.pageContext}
      location = {props.location}
  >
    <TitleBanner title={data.page.title} />
    <div className="entry">
    <div className="entry-content container px-5 container mt-16" >
      <div className={Styles.topSection} >
        <div className="relative">

          <div className={Styles.box} >
            <BgPattern className={`${BgStyle.pattern3} ${Styles.boxBackground}`}/>
            <div className="bg-white h-full w-full  px-5 py-8 ">
              <h3
                dangerouslySetInnerHTML={{__html:data.page.acf.contact_us_page_builder.top_section.title}}
              />
              <p
                dangerouslySetInnerHTML={{__html:data.page.acf.contact_us_page_builder.top_section.text}}
              />
              <h3
                dangerouslySetInnerHTML={{__html:data.page.acf.contact_us_page_builder.top_section.social_title}}
              />
              <div className=" text-gray-200 text-2xl" style={{marginBottom: 35}}>
                <SocialLinksBar iconClassName='text-gray-200!important'/>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.form}>
          <Form {...data.gravityForm} />
        </div>
      </div>
      <div className={Styles.coordinates}>
        <div className={Styles.coordinatesInfo}>
          <h3 
            dangerouslySetInnerHTML={{__html:data.page.acf.contact_us_page_builder.contact.title}}
          />
          <div>
            <div className='flex items-center mb-4' style={{maxWidth: 300}}>
              <div className='mr-30px'>
                <PinIcon />
              </div>
              <p>
                {data.wordpressAcfOptions.options.postal_address}
              </p>
            </div>
            <div className='flex items-center mb-4' style={{maxWidth: 300}}>
              <div className='mr-30px'>
                <PhoneIcon />
              </div>
              <p className='m-0'>
                Phone: {data.wordpressAcfOptions.options.phone}
              </p>
            </div>
            <div className='flex items-center mb-4' style={{maxWidth: 300}}>
              <div className='mr-30px'>
                <EnvelopeIcon />
              </div>
              <p className='m-0'>
                {data.wordpressAcfOptions.options.contact_email}
              </p>
            </div>
          </div>
        </div>

        <div className={Styles.mapContainer}>
          <Map lat={data.page.acf.contact_us_page_builder.contact.map.lat} lng={data.page.acf.contact_us_page_builder.contact.map.lng} style={{height: 496, width: "100%"}}/>
        </div>
      </div>

  <TwitterTimeline numberOfTweets={4}/>
    </div>
    </div>
  </Layout>
  )
}



export default ContactUsTemplate

export const SingleCollabQuery = graphql `
 query contactQuery($id: String!, $form_id: Int!){
   page:wordpressPage(id:{eq:$id}) {
     title
     content
     type
     wordpress_id
     acf {
      contact_us_page_builder{
       top_section {
         title
         text
         social_title
       }
       form {
         title
         description
         labelPlacement
         descriptionPlacement
         version
         wordpress_id
         nextFieldId
         useCurrentUserAsAuthor
         postContentTemplateEnabled
         postTitleTemplateEnabled
         postTitleTemplate
         postContentTemplate
         is_active
         date_created
         is_trash
       }
       contact {
         title
         map {
           address
           lat
           lng
         }
       }
     }
   }
   }
   wordpressAcfOptions {
     options {
       contact_email
       phone
       postal_address
     }
   }
   gravityForm(wordpress_id:{eq:$form_id}) {
     wordpress_id
     fields: wordpress_fields
   }
 }

`
