import React from "react"
import { Map, GoogleApiWrapper, Marker } from "google-maps-react"

const MapContainer = ({ google, lat, lng, style }) => (
	<div style={style}>
		<Map google={google} zoom={15} style={style} initialCenter={{ lat, lng }}>
			<Marker position={{ lat, lng }} />
		</Map>
	</div>
)

export default GoogleApiWrapper({
	apiKey: "AIzaSyD-4t9PbFlL9zq6vZZHXBEh-NkvVWgVj9k",
})(MapContainer)
