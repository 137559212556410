import React, {useState} from 'react'
import FormFieldRenderer, {SubmitButton} from './fields'
import {Formik} from 'formik'
import {onChangeHandler, normalizeFormFields} from './utils'
import {submit} from './api'



export default  (formProps) => {
  const [fields, initialValues] = normalizeFormFields(formProps.fields)
  const checkboxes = {}
  const [submitted, setSubmitted] = useState(false)
  return (
    <Formik
      initialValues = {initialValues}
      onSubmit={
        (values, {setSubmitting, setFieldError, setFieldTouched, setStatus}) => {
          setSubmitted(false)
          //transorm values to submit
          let gf_values = {}
          Object.keys(values).forEach((key)=>{
            if(values[key] instanceof Object && values[key].value) {
              gf_values['input_'+key] = values[key].value
            } else if(values[key] === true){
              gf_values['input_'+key+'_1'] = true
              gf_values['input_'+key+'_2'] = checkboxes[key]
            }else {
              gf_values['input_'+key] = values[key]
            }
            
          })
          //transform selects

          const select_ids = [].concat(formProps.fields.filter(field=>field.type==="select")).map(field=>field.id)
          select_ids.map(selectId=>{
            if(gf_values.hasOwnProperty('input_'+selectId)){
              const current_value = gf_values['input_'+selectId]
              if(Array.isArray(current_value)){
                gf_values['input_'+selectId] = current_value.map(i=>i.value)
              }else{
                gf_values['input_'+selectId] = current_value.value
              }
            }
          })

          submit(formProps.wordpress_id, JSON.stringify(gf_values))
            .then((response)=>{
              if(response.data.is_valid && response.data.confirmation_type === "message"){
                setStatus(response.data)
              }
              setSubmitted(true)
            })
            .catch((error)=> {
              if(error.response && error.response.data){
                if(!error.response.data.is_valid){
                  const errors = error.response.data.validation_messages;
                  
                  setStatus(error.response.data.validation_messages)
                  Object.entries(error.response.data.validation_messages).forEach(entry=>{
                    setFieldError(entry[0], entry[1])
                    setFieldTouched(entry[0], true)
                  })
                }
              }
            })
            .then(()=>{
              setSubmitting(false)
            })
        }
      }
    >
    {props => {
      const {
        values,
        touched,
        errors,
        dirty,
        status,
        setStatus,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        setFieldValue,
        setFieldTouched,
        setFieldError
      } = props;
      return(
        <>
        
        <form onSubmit={handleSubmit}>
          {fields && fields.map((field,index)=>{
            if(field.type === 'consent') {
              checkboxes[field.id] = field.checkboxLabel;
            }
            return (
              <FormFieldRenderer
                {...field}
                id={field.id}
                name={field.id}
                key={index}
                value={props.values[field.id]}
                onChange={e=>{onChangeHandler(e, field, status, setStatus, setFieldValue, handleChange)}}

                onBlur={field.type==="select"?props.setFieldTouched:props.handleBlur}
                errors={props.errors[field.id]}
                touched={props.touched[field.id]}
                dirty={props.dirty[field.id]}
                status={props.status && props.status.hasOwnProperty(field.id)?props.status[field.id]:undefined}
              />
          )})}
        </form>
          {submitted && status.is_valid && status.confirmation_message &&
           <div className='text-green my-3 font-bold' dangerouslySetInnerHTML={{__html: status.confirmation_message}}/>
          
          }
          {submitted && status.is_valid ?
            ''
            :
            <div className="flex justify-start mt-30px">
              <SubmitButton
                className="uppercase"
                label="submit"
                onClickHandler={handleSubmit}
                disabled={isSubmitting}
              />
            </div>
          }
        </>
      )
    }}
    </Formik>
  )
}

